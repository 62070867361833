import React, { useEffect, useState, useRef } from 'react';
import { Box, Button, Container, Grid, Image, Loader } from '@mantine/core';
import { FormLandingOrder, FormOrder } from '../components/Sections';
import { useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { IconPlus } from '@tabler/icons-react';
import { useScrollIntoView, useWindowScroll } from '@mantine/hooks';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useStore from '../store/useStore';
import ReactPixel from 'react-facebook-pixel';


export function LandingProduct () {
    let { id } = useParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const [product, setProduct] = useState<any>(null)
    const [displayButton, setDisplayButton] = useState(true)
    const [scroll, scrollTo] = useWindowScroll();
    const { scrollIntoView, targetRef, } = useScrollIntoView<HTMLDivElement, HTMLDivElement>({
        onScrollFinish: () => {
            setDisplayButton(false)
        }
    });
    const [imageLoaded, setImageLoaded] = useState(false);
    const dataStore = useStore((state: any) => state.store);
    let [marketer, setMarketer] = useState<any>(null);
    let [priceMarketer, setPriceMarketer] = useState<number>(0);

    useEffect(() => {
        if (searchParams.get("ref") && searchParams.get("ref") !== "") {
            axios.get(`${process.env.REACT_APP_API_URL}/userByReferralCode/${searchParams.get("ref")}`)
            .then(({data}) => {
                setMarketer(data);
            })
            .catch((error) => console.log(error));
        }
    }, [searchParams.get("ref")])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/landing-product/${id}`)
        .then(({data}) => {
            setProduct(data)
        })
        .catch((error) => console.log(error));
    }, [id])

    useEffect(() => {
        if (marketer && "_id" in marketer && product && "priceMarketer" in product && product.priceMarketer.length > 0) {
            const dataMarketer = product?.priceMarketer?.filter((item: any) => item.idMarketer === marketer._id)
            if (dataMarketer.length > 0) {
                setPriceMarketer(dataMarketer[0]?.price || 0)
            } else {
                setPriceMarketer(0)
            }
        }
    }, [product, marketer])
    
    useEffect(() => {
        if (marketer && marketer?._id && marketer?._id !== "") {
            axios.get(`${process.env.REACT_APP_API_URL}/pixels?idMarketer=${marketer?._id}`)
            .then(({data}) => {
                const advancedMatching = undefined;
                data?.map((item: any, index: number) => {
                    if (item?.apiKey !== "" && item?.apiKey.length > 10) {
                        ReactPixel.init(item?.apiKey, advancedMatching, {
                            autoConfig: true,
                            debug: true
                        });
                    }
                })
                ReactPixel.pageView();
            })
            .catch((error) => console.log(error));
        }
    }, [marketer])

    useEffect(() => {
        if (scroll.y <= 100) {
            setDisplayButton(true)
        }
    }, [scroll])

    return (
        <>
            {!imageLoaded
                ? <div style={{position: "fixed", top: 0, bottom: 0, right: 0, left: 0, height: "100vh", width: "100vw", zIndex: 100, background: "#fffffff0", display: 'flex', flexDirection: 'column', justifyContent: "center", alignItems: "center"}}>
                    <Loader color={dataStore?.information?.backgroundColor || "#645cbb"} size="xl" />
                </div>
                : null
            }
            <LazyLoadImage
                // src={`${process.env.REACT_APP_API_URL_IMAGES}/${product?.image}.webp`}
                src={`${process.env.REACT_APP_API_URL_IMAGES}/${product?.image}`}
                alt={""}
                height={"auto"}
                width={"100%"}
                effect='blur'
                threshold={100}
                delayTime={0}
                style={{cursor: 'pointer'}}
                onClick={() => scrollIntoView()}
                onLoad={() => setImageLoaded(true)}
                placeholderSrc='/loading.gif'
            />

            <Container size={'lg'} mt={130}>
                <Grid gutter="xl" mt={50} justify='center'>
                    <Grid.Col span={{ base: 12, sm: 12, md: 10, lg: 8, xl: 8 }}>
                        {imageLoaded
                            ? <FormLandingOrder targetRef={targetRef} data={product} marketer={marketer ? {...marketer, priceMarketer} : null} />
                            : null
                        }
                    </Grid.Col>
                </Grid>
            </Container>

            {displayButton
                ? <Box style={{position: "fixed", bottom: 20, left: 20}}>
                    <Button className='pulse-button'
                        variant="filled" size={'lg'} color={dataStore?.information?.backgroundColor || "#645cbb"}
                        leftSection={<IconPlus size={18} />}
                        onClick={() => scrollIntoView()}
                    >أطلب الان</Button>
                </Box>
                : null
            }
            
        </>
    );
}